import React from "react";

import ProductCategories from "../../components/ProductCategories";
import ProductSmokingHero from "../../components/ProductSmokingHero";
import AppFooter from "../../components/AppFooter";
import ProductHero from "../../components/ProductHero";
import ProductValues from "../../components/ProductValues";
import ProductHowItWorks from "../../components/ProductHowItWorks";
import ProductCTA from "../../components/ProductCTA";
import AppAppBar from "../../components/AppAppBar";
import "./home.css";
import Other from "../../components/Other";

const Home = () => {
  return (
    <div className="home-body">
      <AppAppBar />
      <ProductHero />
      <Other />
      <ProductValues />
      <ProductCategories />
      <ProductHowItWorks />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter />
    </div>
  );
};

export default Home;
