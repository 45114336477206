import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "./microcomponents/Typography";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="./productValues1.svg"
                alt="suitcase"
                sx={{ height: 55 }}
              />
              <Typography
                variant="h6"
                sx={{ my: 5 }}
                color="primary"
                className="typotypo"
              >
                Misión
              </Typography>
              <Typography variant="body2" className="centercenter">
                Generar valor para nuestros clientes de los sectores público y
                privado a través de la integración de soluciones para la gestión
                de TI que contribuya a sus objetivos de negocio y aseguren la
                rentabilidad y continuidad de nuestra empresa.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="./productValues2.svg"
                alt="graph"
                sx={{ height: 55 }}
              />
              <Typography
                variant="h6"
                sx={{ my: 5 }}
                color="primary"
                className="typotypo"
              >
                Nuestros Valores
              </Typography>
              <Typography variant="body1" className="centercenter">
                <ul>
                  <li>Cooperación</li>
                  <li>Innovación</li>
                  <li>Seguridad</li>
                  <li>Amabilidad</li>
                  <li>Confianza</li>
                  <li>Atención</li>
                  <li>Motivación</li>
                  <li>Compromiso</li>
                  <li>Competitividad</li>
                  <li>Ética profesional</li>
                  <li>Apertura al cambio </li>
                </ul>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="./productValues3.svg"
                alt="clock"
                sx={{ height: 55 }}
              />
              <Typography
                variant="h6"
                sx={{ my: 5 }}
                color="primary"
                className="typotypo"
              >
                Visión
              </Typography>
              <Typography variant="body2" className="centercenter">
                Ser una empresa altamente competitiva en la integración de
                soluciones de TI para el mercado latinoamericano con compromiso
                y lealtad en la relación con nuestros colaboradores, clientes,
                proveedores y la sociedad.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
