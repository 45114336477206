import * as React from "react";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "./microcomponents/Typography";

function Copyright() {
  return (
    <React.Fragment>
      <Link color="inherit" href="/">
        © RECURSOS INFORMÁTICOS 2018
      </Link>{" "}
    </React.Fragment>
  );
}

export default function AppFooter() {
  return (
    <Typography component="footer" sx={{ bgcolor: "secondary.main" }}>
      <Container
        sx={{ justifyContent: "center", display: "flex", padding: "1rem" }}
      >
        <img src="logo.svg" alt="logogo" className="img-logo" />
      </Container>
      <Container
        sx={{ justifyContent: "center", display: "flex", padding: "1rem" }}
      >
        <Copyright />
      </Container>
    </Typography>
  );
}
