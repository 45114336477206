import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "./microcomponents/Typography";

function Other() {
  return (
    <Container component="section" sx={{ mt: 10, mb: 10, display: "flex" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: "block", xs: "none" }, position: "relative" }}
        >
          <Box
            sx={{
              position: "absolute",
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: "100%",
              background:
                "url(/static/themes/onepirate/productCTAImageDots.png)",
            }}
          />
          <Box
            component="img"
            src="https://images.unsplash.com/photo-1517430816045-df4b7de11d1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80"
            alt="call to action"
            sx={{
              position: "absolute",
              top: -28,
              right: 0,
              bottom: 0,
              width: "100%",
              maxWidth: 600,
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "primary.main",
              py: 8,
              px: 3,
            }}
          >
            <Box sx={{ maxWidth: 400 }}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                color="secondary"
              >
                ¿ Quiénes somos ?
              </Typography>
              <Typography variant="body1" color="white">
                Somos una empresa dedicada a proveer soluciones de tecnologías
                de información que te ayudan a gestionar tu empresa.
                Comprometidos con un servicio de calidad y la relación de
                confianza de nuestros clientes, garantizamos la más adecuada
                prestación de servicios informáticos, asesoramiento y de
                telecomunicaciones, orientados hacia el mejoramiento de su
                calidad empresarial
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Other;
