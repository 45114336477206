import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "./microcomponents/AppBar";
import Toolbar from "./microcomponents/Toolbar";

function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ flex: 1 }} />
          <Link color="inherit" href="/">
            <img
              src="./logo.svg"
              alt="recursos informaticos"
              className="img-logo"
            />
          </Link>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
