import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
/*
import Income from "./pages/income";
import Login from "./pages/login";
import OnBoarding from "./pages/onBoarding";
import SignUp from "./pages/sign up";
*/

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
