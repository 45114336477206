import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#0A2240",
    },
    secondary: {
      main: "#8AE0B0",
    },
    error: {
      main: "#D41818",
    },
    warning: {
      main: "#E89A1B",
    },
    success: {
      main: "#2D9D78",
    },
  },
});

export default theme;
