import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "./microcomponents/Typography";

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 9,
      }}
    >
      <Button
        sx={{
          border: "4px solid currentColor",
          borderRadius: 0,
          height: "auto",
          py: 2,
          px: 5,
        }}
      >
        <Typography variant="h4" component="span">
          Tienes dudas? Necesitas ayuda?
        </Typography>
      </Button>
      <Typography variant="subtitle1" sx={{ my: 3 }} className="centercenter ">
        Escríbenos un mensaje. Ponte en contacto con nosotros, proporciónanos
        tus datos y cuéntanos mas sobre tus requerimientos. Un asesor se pondrá
        en contacto lo antes posible
      </Typography>
      <Box
        component="img"
        src="./correooooo.png"
        alt="buoy"
        sx={{ width: 60 }}
      />
      <Typography variant="subtitle1" sx={{ my: 3 }} className="centercenter ">
        tecnologia@rinformaticos.com
      </Typography>
    </Container>
  );
}

export default ProductSmokingHero;
