import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Typography from "./microcomponents/Typography";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AddReactionIcon from "@mui/icons-material/AddReaction";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "primary.main", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography
          variant="h4"
          marked="center"
          component="h2"
          sx={{ mb: "2rem" }}
          color="white"
        >
          Nos comprometemos a
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <AddReactionIcon
                  fontSize="large"
                  sx={{ mb: "3rem" }}
                  color="secondary"
                />
                <Typography variant="body1" align="center" color="white">
                  Dar respuestas ágiles y oportunas a todas las partes
                  interesadas en nuestros productos y servicios.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <AccessibilityNewIcon
                  fontSize="large"
                  sx={{ mb: "3rem" }}
                  color="secondary"
                />
                <Typography variant="body1" align="center" color="white">
                  Ofrecer un servicio digno, respetuoso y cálido, con honestidad
                  y confidencialidad.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <PersonSearchIcon
                  fontSize="large"
                  sx={{ mb: "3rem" }}
                  color="secondary"
                />
                <Typography variant="body1" align="center" color="white">
                  Disponer de profesionales altamente calificados orientados a
                  la prestación de servicio con la máxima calidad y eficiencia
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={5} sx={{ mt: "2rem" }}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <PointOfSaleIcon
                  fontSize="large"
                  sx={{ mb: "3rem" }}
                  color="secondary"
                />
                <Typography variant="body1" align="center" color="white">
                  Enfocar nuestra gestión en la optimización de recursos y
                  procesos, incrementando nuestra productividad y
                  competitividad.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <AccessTimeIcon
                  fontSize="large"
                  sx={{ mb: "3rem" }}
                  color="secondary"
                />
                <Typography variant="body1" align="center" color="white">
                  Entregar proyectos llave en mano que cumplan con los objetivos
                  y requerimientos establecidos por nuestros clientes.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
